import React, { lazy } from 'react'

const Home = lazy(() =>
  import('./Home').then(module => ({
    default: module.Home,
  }))
)

const InvoiceDocument = lazy(() =>
  import('../Services/InvoiceDocument').then(module => ({
    default: module.InvoiceDocument,
  }))
)

export const BOOKING_PATH = {
  ROOT: '/',

  BOOKING_INVOICE: '/booking/detail/invoice',
  HOTEL_GUESTAPP: '/guestapp/hotel',
 
}
const BookingRoutes = [
  {
    props: { exact: true, path: BOOKING_PATH.ROOT },
    component: <Home />,
  },
  {
    props: { exact: true, path: BOOKING_PATH.BOOKING_INVOICE },
    component: <InvoiceDocument Mode={'Booking'} />,
  },
]

export default BookingRoutes
