import { CopyRight, Spinner } from '@ifca-ui/neumorphism'
import { Button, Card, CardContent, makeStyles, TextField } from '@material-ui/core'
import HotelX from 'assets/images/app-logo-b.svg'
import React, { ReactNode, useContext, useEffect, useState } from 'react'
import {
  useHotelDetailsLazyQuery,
  useIsGuestExistLazyQuery,
} from 'generated/graphql'
import queryString from 'query-string'
import { useHistory, useLocation } from 'react-router'
import AppContext, { AppContextProps } from 'containers/Context/Context'
import PhoneInput from 'react-phone-input-2'

interface HotelGuestAppProps {
  image?: string
}

const useStyles = makeStyles(() => ({
  layout: {
    display: "flex",
    alignItems: "center",
  },
  authBox: {
    maxWidth: '400px',
    minWidth: '280px',
    margin: '0 auto',
    width: '80vw',
    padding: '12px',
    borderRadius: '10px',
    backgroundColor: 'white',
    height: 'fit-content',
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  title: {
    fontSize: '18px',
    margin: '20px 0px'
  },
  cardBox: {
    boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.1), 0px -1px 6px rgba(0, 0, 0, 0.1)',
    width: '100%',
  },
  cardDetail: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px'
  }
}));

export const HotelGuestApp = (props: HotelGuestAppProps) => {
  const { image } = props
  const { RootState, RootDispatch } = useContext<AppContextProps>(AppContext)
  const location = useLocation()
  const history = useHistory()
  const classes = useStyles()
  const urlInfo = queryString.parse(location.search)
  const HotelID: any = urlInfo?.Hotel
  const [btnDisable, setBtnDisable] = useState(false)
  const [guestInput, setGuestInput] = useState({
    RoomNo: '',
    ContactNo: '',
    Name: ''
  })

  const [
    HotelDetailsQuery,
    { 
      loading,
      data: { HotelDetails } = { HotelDetails: null } 
    }
  ] = useHotelDetailsLazyQuery({
    fetchPolicy: 'no-cache',
  })

  const [
    GuestExistQuery,
    { 
      loading: guestLoading,
      data: { IsGuestExist } = { IsGuestExist: {} } 
    }
  ] = useIsGuestExistLazyQuery({
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    RootDispatch({
      type: 'HeaderSection',
      payload: {},
    })
  }, [])

  useEffect(() => {
    HotelDetailsQuery({
      variables: {
        HotelID: HotelID
      }
    })
  }, [HotelID])

  const isDisable = () => {
    const check = guestInput?.ContactNo === '' || guestInput?.RoomNo === ''
    return check
  }

  const onConfirm = () => {
    setBtnDisable(true)
    GuestExistQuery({
      variables: {
        HotelID: HotelID,
        RoomNo: guestInput?.RoomNo,
        ContactNo: guestInput?.ContactNo
      }
    }).then(i => {
      if (i.data?.IsGuestExist !== false) {
        window.location.href = i.data.IsGuestExist
      } else {
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: 'Invalid Room No/Contact No',
          },
        })
      }
      setBtnDisable(false)
    })
  }

  return loading ? (<Spinner />) : (
    <div
      className={classes.layout}
      style={{
        background: 'url("' + image + '") no-repeat center center fixed',
        backgroundSize: 'cover',
        height: '100vh',
        width: '100%',
      }}
    >
      <div className={classes.authBox}>
        <img 
          src={HotelDetails?.LogoImage?.ImageURL}
          alt=""
          style={{
            width: '100px',
            maxWidth: '250px'
          }}
        />
        <span 
          className={classes.title} 
          style={{ fontWeight: '600' }}
        >
          Guest App
        </span>
        <Card className={classes.cardBox}>
          <CardContent 
            className={classes.cardDetail} 
            style={{ padding: '10px' }}
          >
            <span style={{ fontSize: '13px' }}>
              Please complete the following form:
            </span>
            <TextField
              label="Room Number"
              fullWidth
              required
              value={guestInput?.RoomNo}
              onChange={e => {
                setGuestInput({
                  ...guestInput,
                  RoomNo: e.target.value
                })
              }}
              margin="normal"
              style={{ paddingBottom: '10px' }}
            />
            <PhoneInput
              containerClass="contact-input-new"
              inputProps={{
                name: 'ContactNo',
                required: true,
              }}
              specialLabel="Contact Number *"
              country={'my'} //Country prefix
              value={guestInput?.ContactNo}
              onChange={e => {
                setGuestInput({
                  ...guestInput,
                  ContactNo: e
                })
              }}
            />
            <TextField
              label="Name"
              fullWidth
              value={guestInput?.Name}
              onChange={e => {
                setGuestInput({
                  ...guestInput,
                  Name: e.target.value
                })
              }}
              margin="normal"
              disabled={true}
            />
            <div style={{ display: 'flex', justifyContent: 'center', padding: '8px 0px'}}>
              <Button
                style={{ 
                  width: '100px', 
                  margin: '0px', 
                  backgroundColor: isDisable() ? undefined : '#2c6eff',
                  borderRadius: '10px',
                  textTransform: 'none',
                  fontWeight: '600'
                }}
                variant='contained'
                color='primary'
                disabled={isDisable() || btnDisable}
                onClick={() => onConfirm()}
              >
                Confirm
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
      <CopyRight title={<img src={HotelX} alt="logo" width={50} />} />
    </div>
  )
}