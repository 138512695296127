import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  Observable
} from "@apollo/client";
import { createUploadLink } from 'apollo-upload-client'
import { setContext } from '@apollo/client/link/context';
import { onError } from "@apollo/client/link/error";

//! Hotel-Node end point
export let hotelxNodeUrl
switch (process.env.REACT_APP_API_URL) {
case 'dev-build':
  hotelxNodeUrl = 'https://hotelx-dev-api.ifca.io/hotelx'
  break
case 'prod-build':
  hotelxNodeUrl = 'https://hotelxapi.hotelx.asia/hotelx'
  break
case 'promenade-build':
  hotelxNodeUrl = 'https://hotelxapi-promenade.hotelx.asia/hotelx'
  break
case 'imperial-build':
  hotelxNodeUrl = 'https://hotelxapi-imperial.hotelx.asia/hotelx'
  break
case 'uat-promenade-build':
  hotelxNodeUrl = 'https://hotelxapi-promenade-uat.hotelx.asia/hotelx'
  break
case 'uat-build':
  hotelxNodeUrl = 'https://hotelx-uat-api.ifca.io/hotelx'
  break
case 'uat-promenade-build':
  hotelxNodeUrl = 'https://hotelxapi-promenade-uat.hotelx.asia/hotelx'
  break
default:
  hotelxNodeUrl = 'http://localhost:8000/hotelx'
  break
}
export let RedirectUrl = ''
switch (process.env.REACT_APP_API_URL) {
case 'dev-build':
  RedirectUrl = 'https://hotelx-dev-guest.ifca.io'
  break
case 'uat-build':
  RedirectUrl = 'https://hotelx-uat-guest.ifca.io'
  break
case 'uat-promenade-build':
  hotelxNodeUrl = 'https://guest-promenade-uat.hotelx.asia'
  break
case 'prod-build':
  RedirectUrl = 'https://guest.hotelx.asia'
  break
case 'promenade-build':
  RedirectUrl = 'https://guest-promenade.hotelx.asia'
  break
case 'imperial-build':
  RedirectUrl = 'https://hotelxapi-imperial.hotelx.asia/hotelx'
  break
case 'uat-promenade-build':
  hotelxNodeUrl = 'https://guest-promenade-uat.hotelx.asia'
  break
default:
  RedirectUrl = 'http://localhost:3301'
  break
}

export const UploadLink = createUploadLink({
uri: hotelxNodeUrl,
credentials: 'include',
})
export const RequestLink = new ApolloLink(
(operation, forward) =>
  new Observable(observer => {
    let handle: any
    Promise.resolve(operation)
      .then(operation => {
        const accessToken = JSON.parse(localStorage.getItem('GuestInfo'))
          ?.accessToken
        if (accessToken) {
          operation.setContext({
            headers: {
              authorization: `bearer ${accessToken}`,
            },
          })
        }
      })
      .then(() => {
        handle = forward(operation).subscribe({
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        })
      })
      .catch(observer.error.bind(observer))

    return () => {
      if (handle) handle.unsubscribe()
    }
  })
)
export const AuthLink = setContext((_, { headers }) => {
// get the authentication token from local storage if it exists
const token = JSON.parse(localStorage.getItem('GuestLoginInfo'))
?.accessToken;
// return the headers to the context so httpLink can read them
return {
  headers: {
    ...headers,
    authorization: token ? `Bearer ${token}` : "",
  }
}
});
const errorLink = onError(({ graphQLErrors, networkError }) => {
if (graphQLErrors)
  graphQLErrors.forEach(({ message, locations, path }) =>
    console.log(
      `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
    ),
    
  );

if (networkError) console.log(`[Network error]: ${networkError}`);
});
export const Client = new ApolloClient({
  uri: hotelxNodeUrl,
  cache: new InMemoryCache(),
  link: ApolloLink.from([AuthLink, RequestLink, errorLink, UploadLink as any]),
 
});